import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material";
import TextStyles from './TextStyles.js'
import { ref, set } from "firebase/database";
import { rtdb } from "./firebase.js";
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddIcon from '@mui/icons-material/Add';
import picStorageUpload from "./picUpload.js";
import picUrl from './picUrl.js';

function Component(props) {
    const [NewBadgeName, setNewBadgeName] = useState('');
    const [NewBadgePicture, setNewBadgePicture] = useState();
    const [DisabledButton, setDisabledButton] = useState(true);
    const [badgesList, setbadgesList] = useState([]);
    const [ConfirmationDialogOpen, setConfirmationDialogOpen] = useState(null);
    const [DragOver, setDragOver] = useState(false);

    useEffect(() => {
        setDisabledButton(!NewBadgeName || !NewBadgePicture);
    }, [NewBadgeName, NewBadgePicture])

    /*     useEffect(() => {
            setNewBadgeName('');
            setNewBadgePicture('\uf49d');
        }, []) */

    useEffect(() => {
        let pre = Object.keys(props.Badges).toSorted() || [];
        setbadgesList(pre);
    }, [props.Badges])

    const elemCount = (name) => {
        let count = 0;
        for (let p in props.List)
            if (props.List[p].badge === name) count++;
        return count
    }

    const picUpload = (files, fn) => {
        picStorageUpload(true, files, fn, Object.keys(props.Pictures))
    }

    const PictureDrop = (ev, fn) => {
        ev.preventDefault();
        setDragOver(false);
        if (ev.dataTransfer.items) {
            let pics = [];
            for (let item of [...ev.dataTransfer.items])
                if (item.kind === "file")
                    pics.push(item.getAsFile());
            picUpload(pics, fn);
        } else
            picUpload(ev.dataTransfer.files, fn);
    }

    return <Box>
        <Box
            sx={{
                ...TextStyles.h2,
                textAlign: 'center',
            }}
        >
            Etiquetas
        </Box>
        <Box
            sx={{
                margin: 'auto',
                width: 'fit-content',
                display: 'flex',
                flexDirection: 'column',
                gap: 1
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1,
                    alignItems: 'center',
                    userSelect: 'none',
                }}
            >
                <Box
                    component="fieldset"
                    sx={{
                        border: '1px solid ' + (props.DarkMode ? '#ffffff3b' : '#0000003b'),
                        borderRadius: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                        width: '3em',
                        height: '4em',
                        marginTop: '-0.5em',
                        borderWidth: 2,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundImage: NewBadgePicture ? ('url(' + picUrl(props.Pictures, NewBadgePicture, true) + ')') : 'none',
                        cursor: 'pointer',
                    }}
                    onClick={e => document.getElementById('badgeInput').click()}
                    onDragOver={ev => {
                        ev.preventDefault();
                        setDragOver(true);
                    }}
                    onDragLeave={ev => {
                        ev.preventDefault();
                        setDragOver(false);
                    }}
                    onDrop={e => PictureDrop(e, setNewBadgePicture)}
                >
                    <input
                        id="badgeInput"
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={e => picUpload(e.target.files, setNewBadgePicture)}
                        style={{ display: 'none' }}
                    />
                    {!NewBadgePicture ? (
                        <AddIcon
                            sx={{
                                fontSize: 20,
                                opacity: DragOver ? 1 : .5,
                            }}
                        />
                    ) : ''}
                </Box>


                <TextField
                    label='Etiqueta'
                    sx={{
                        flexGrow: 1,
                        '& input': {
                            py: 1.5,
                            px: 2,
                        }
                    }}
                    value={NewBadgeName}
                    onChange={e => setNewBadgeName(e.target.value)}
                />
                <IconButton
                    aria-label="add"
                    color="info"
                    disabled={DisabledButton}
                    onClick={() => {
                        set(ref(rtdb, "public/Categories/Badge/" + NewBadgeName), NewBadgePicture)
                        setNewBadgeName('');
                        setNewBadgePicture('\uf49d');
                    }}
                >
                    <CheckCircleIcon />
                </IconButton>
            </Box>
            {badgesList.map((name, i) => {
                let pic = props.Badges[name],
                    count = elemCount(name);
                return <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 1,
                        alignItems: 'center',
                        userSelect: 'none',
                        pl: 0,
                        '& button': {
                            transition: '.3s',
                            opacity: 0,
                        },
                        '&:hover button': {
                            opacity: 1,
                        }
                    }}
                    key={'list-Badges-' + name}
                >
                    <Box
                        sx={{
                            height:'3em',
                            width:'3em',
                            backgroundImage: 'url(' + picUrl(props.Pictures, pic, true) + ')',
                            backgroundRepeat:'no-repeat',
                            backgroundSize:'contain',
                        }}
                        onClick={() => {
                            setNewBadgeName(name);
                            setNewBadgePicture(pic);
                        }}
                    />
                    <Box
                        sx={{
                            flexGrow: 1,
                        }}
                        onClick={() => {
                            setNewBadgeName(name);
                            setNewBadgePicture(pic);
                        }}
                    >
                        {name + ' (' + count + ')'}
                    </Box>
                    <IconButton
                        aria-label="delete"
                        disabled={!!count}
                        color='error'
                        onClick={() => setConfirmationDialogOpen(name)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Box>
            })}
        </Box>
        <Dialog
            onClose={() => setConfirmationDialogOpen(null)}
            open={ConfirmationDialogOpen !== null}
        >
            <DialogTitle id="simple-dialog-title">Confirma la eliminación de la Etiqueta</DialogTitle>
            <DialogContent>
                {ConfirmationDialogOpen}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => setConfirmationDialogOpen(null)}
                    color="primary"
                >
                    Cancelar
                </Button>
                <Button
                    onClick={() => {
                        set(ref(rtdb, "public/Categories/Badge/" + ConfirmationDialogOpen), null)
                        setConfirmationDialogOpen(null)
                    }}
                    color="primary"
                >
                    Borrar
                </Button>
            </DialogActions>
        </Dialog>
    </Box>
}

export default Component