import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getDatabase} from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCGBvh-wHf41mpqf0nGlWn43-FcsPeYxSQ",
  authDomain: "argento-gusto.firebaseapp.com",
  // authDomain: "argentogusto.bollo.com.ar",
  // authDomain: "menu.argentogusto.com.ar",
  projectId: "argento-gusto",
  storageBucket: "argento-gusto.appspot.com",
  messagingSenderId: "709945496975",
  appId: "1:709945496975:web:a74cf4b711634342eb67fa",
  measurementId: "G-P2R03CNJH1"
};

const app = initializeApp(firebaseConfig);
const authApp = getAuth(app);
authApp.languageCode = 'es';

export const
  db = getFirestore(app),
  rtdb=getDatabase(app),
  storage = getStorage(app),
  auth = authApp;

