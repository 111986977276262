async function fetchAddress(MapLocation) {
    if (!MapLocation.lat || !MapLocation.lng)
        return null;
    const url = `https://geocode.maps.co/reverse?lat=${MapLocation.lat}&lon=${MapLocation.lng}&api_key=66d1d443652d2459292793etja6bb46`;
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`Response status: ${response.status}`);
        }
        const json = await response.json();
        console.log(json.address)
        return json.address;
    } catch (error) {
        return null
    }
}

export default fetchAddress
