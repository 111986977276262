import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import TextStyles from './TextStyles.js'
import { ref, set } from "firebase/database";
import { rtdb } from "./firebase.js";
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddIcon from '@mui/icons-material/Add';
import picUrl from './picUrl.js';
import picStorageUpload from "./picUpload.js";

function Component(props) {
    const [NewCategoryName, setNewCategoryName] = useState('');
    const [NewCategoryIcon, setNewCategoryIcon] = useState(props.Title.toLowerCase().includes('local') ? '\uf54f' : '\uf49d');
    const [DisabledButton, setDisabledButton] = useState(true);
    const [categoriesList, setcategoriesList] = useState([]);
    const [ConfirmationDialogOpen, setConfirmationDialogOpen] = useState(null);
    const [DragOver, setDragOver] = useState(false);
    const [NewCatLogo, setNewCatLogo] = useState();
    const [NewCatBg, setNewCatBg] = useState();

    const unicodeIconsList = ['\uf72d', '\uf725', '\uf826', '\uf551', '\uf824', '\uf823', '\uf821', '\uf4d8', '\uf820', '\uf81f', '\uf81e', '\uf707', '\uf819', '\uf818', '\uf817', '\uf705', '\uf816', '\uf814', '\uf094', '\uf810', '\uf80f', '\uf86b', '\uf805', '\uf79d', '\uf803', '\uf7fe', '\uf578', '\uf7fc', '\uf7fb', '\uf6d7', '\uf6d6', '\uf7f6', '\uf6c7', '\uf563', '\uf73b', '\uf7f1', '\uf7f0', '\uf7ef', '\uf787', '\uf6bd', '\uf786', '\uf7ed', '\uf858', '\uf7ec', '\uf7eb', '\uf5d7', '\uf7e5', '\uf6b1', '\uf5d1', '\uf5ce', '\uf4e3', '\uf72f', '\uf875', '\uf7b7', '\uf7b6', '\uf874', '\uf8c6', '\uf7a1', '\uf7a0', '\uf57b', '\uf000', '\uf869', '\uf79f', '\uf79e', '\uf804', '\uf6e1', '\uf6e0', '\uf0c3', '\uf6c5', '\ue002', '\uf0f4', '\uf561', '\uf858', '\uf517', '\uf0fc', '\uf54f', '\uf49d']
    useEffect(() => {
        console.log('logo', NewCatLogo);
    }, [NewCatLogo])
    useEffect(() => {
        console.log('bg', NewCatBg);
    }, [NewCatBg])

    useEffect(() => {
        setDisabledButton(!NewCategoryName || !NewCategoryIcon);
    }, [NewCategoryName, NewCategoryIcon])

    useEffect(() => {
        setNewCategoryName('');
        setNewCatLogo();
        setNewCatBg();
        setNewCategoryIcon(props.Title.toLowerCase().includes('local') ? '\uf54f' : '\uf49d');
    }, [props.Title])

    useEffect(() => {
        let pre = Object.keys(props.Categories).toSorted() || [];
        let index = pre.indexOf('default');
        if (index)
            pre.splice(index, 1);
        setcategoriesList([...pre, 'default'])
    }, [props.Categories])

    const elemCount = (name) => {
        let count = 0;
        for (let p in props.List)
            if (props.List[p].category === name) count++;
        return count
    }

    const picUpload = (files, fn, transparency) => {
        picStorageUpload(transparency, files, fn, Object.keys(props.Pictures))
    }

    const PictureDrop = (ev, fn, transparency) => {
        ev.preventDefault();
        setDragOver(false);
        if (ev.dataTransfer.items) {
            let pics = [];
            for (let item of [...ev.dataTransfer.items])
                if (item.kind === "file")
                    pics.push(item.getAsFile());
            picUpload(pics, fn, transparency);
        } else
            picUpload(ev.dataTransfer.files, fn, transparency);
    }

    return <Box>
        <Box
            sx={{
                ...TextStyles.h2,
                textAlign: 'center',
            }}
        >
            {props.Title}
        </Box>
        <Box
            sx={{
                margin: 'auto',
                width: 'fit-content',
                display: 'flex',
                flexDirection: 'column',
                gap: 1
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1,
                    alignItems: 'center',
                    userSelect: 'none',
                }}
            >
                <FormControl
                    sx={{
                        '& label:not(.Mui-focused):not(.MuiFormLabel-filled)': { display: 'none' }
                    }}
                >
                    <InputLabel>Icono</InputLabel>
                    <Select
                        label='Icono'
                        className='fas'
                        value={NewCategoryIcon}
                        onChange={e => setNewCategoryIcon(e.target.value)}
                        input={<OutlinedInput label="Icono" />}
                        sx={{
                            '&::before': {
                                pl: 2,
                                fontSize: '1.25em',
                                opacity: NewCategoryIcon ? '1' : '0',
                            },
                            '& .MuiSelect-select': {
                                p: 1.5,
                            }
                        }}
                    >
                        {unicodeIconsList.map((icon, i) => {
                            return <MenuItem
                                key={'iconSelector-' + i}
                                className={'fa-fw fas'}
                                value={icon}
                            >
                                {icon}
                            </MenuItem>
                        })}


                    </Select>
                </FormControl>

                <Box
                    component="fieldset"
                    sx={{
                        border: '1px solid ' + (props.DarkMode ? '#ffffff3b' : '#0000003b'),
                        borderRadius: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                        width: '3em',
                        height: '4em',
                        marginTop: '-0.5em',
                        borderWidth: 1,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundImage: NewCatLogo ? ('url(' + picUrl(props.Pictures, NewCatLogo, true) + ')') : 'none',
                        cursor: 'pointer',
                    }}
                    onClick={e => document.getElementById('logoInput').click()}
                    onDragOver={ev => {
                        ev.preventDefault();
                        setDragOver(true);
                    }}
                    onDragLeave={ev => {
                        ev.preventDefault();
                        setDragOver(false);
                    }}
                    onDrop={e => PictureDrop(e, setNewCatLogo, true)}
                >
                    <legend><small>Logo</small></legend>
                    <input
                        id="logoInput"
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={e => picUpload(e.target.files, setNewCatLogo, true)}
                        style={{ display: 'none' }}
                    />
                    {!NewCatLogo ? (
                        <AddIcon
                            sx={{
                                fontSize: 20,
                                opacity: DragOver ? 1 : .5,
                            }}
                        />
                    ) : ''}
                </Box>

                <Box
                    component="fieldset"
                    sx={{
                        border: '1px solid ' + (props.DarkMode ? '#ffffff3b' : '#0000003b'),
                        borderRadius: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                        width: '3em',
                        height: '4em',
                        marginTop: '-0.5em',
                        borderWidth: 1,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundImage: NewCatBg ? ('url(' + picUrl(props.Pictures, NewCatBg, false) + ')') : 'none',
                        cursor: 'pointer',
                    }}
                    onClick={e => document.getElementById('bgInput').click()}
                    onDragOver={ev => {
                        ev.preventDefault();
                        setDragOver(true);
                    }}
                    onDragLeave={ev => {
                        ev.preventDefault();
                        setDragOver(false);
                    }}
                    onDrop={e => PictureDrop(e, setNewCatBg, false)}
                >
                    <legend><small>Fondo</small></legend>
                    <input
                        id="bgInput"
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={e => picUpload(e.target.files, setNewCatBg, false)}
                        style={{ display: 'none' }}
                    />
                    {!NewCatBg ? (
                        <AddIcon
                            sx={{
                                fontSize: 20,
                                opacity: DragOver ? 1 : .5,
                            }}
                        />
                    ) : ''}
                </Box>

                <TextField
                    label='Categoría'
                    sx={{
                        flexGrow: 1,
                        '& input': {
                            py: 1.5,
                            px: 2,
                        }
                    }}
                    value={NewCategoryName}
                    onChange={e => setNewCategoryName(e.target.value)}
                />
                <IconButton
                    aria-label="add"
                    color="info"
                    disabled={DisabledButton}
                    onClick={() => {
                        let cat = {
                            icon: NewCategoryIcon,
                        };
                        if (!!NewCatLogo) cat.logo = NewCatLogo;
                        if (!!NewCatBg) cat.background = NewCatBg;
                        set(ref(rtdb, "public/Categories/" + props.database + '/' + NewCategoryName), cat)
                        setNewCategoryName('');
                        setNewCategoryIcon(props.Title.toLowerCase().includes('local') ? '\uf54f' : '\uf49d');
                        setNewCatLogo();
                        setNewCatBg();
                    }}
                >
                    <CheckCircleIcon />
                </IconButton>
            </Box>
            {categoriesList.map((name, i) => {
                let icon = props.Categories[name].icon,
                    logo = props.Categories[name].logo,
                    bg = props.Categories[name].background,
                    count = elemCount(name);
                return <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 1,
                        alignItems: 'center',
                        userSelect: 'none',
                        pl: 0,
                        '& button': {
                            transition: '.3s',
                            opacity: 0,
                        },
                        '&:hover button': {
                            opacity: 1,
                        }
                    }}
                    key={'list-' + props.Title + '-' + name}
                >
                    <Box
                        sx={{
                            borderRadius: 3,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                            borderWidth: 1,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundImage: bg ? ('url(' + picUrl(props.Pictures, bg, false) + ')') : 'none',
                            backgroundColor: '#888',
                            cursor: 'pointer',
                            mr: 'auto'
                        }}
                        onClick={() => {
                            setNewCategoryName(name);
                            setNewCategoryIcon(icon);
                            setNewCatLogo(logo);
                            setNewCatBg(bg);
                        }}
                    >
                        <Box
                            sx={{
                                fontSize: 'larger',
                                width: '3em',
                                height: '3em',
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                backgroundImage: logo ? ('url(' + picUrl(props.Pictures, logo, true) + ')') : 'none',
                            }}
                        />
                        <Box
                            className='fa-fw fas'
                            sx={{
                                fontSize: 'larger',
                                borderRadius: 2,
                                backgroundColor: '#fff',
                                width: '2em',
                                height: '2em',
                                lineHeight: 2,
                                color: '#111'
                            }}
                        >
                            {icon}
                        </Box>
                        <Box
                            sx={{
                                textShadow: '0 0 0.25em black, 0 0 0.25em black',
                                mx: 3,
                                fontWeight: 'bold',
                                color: '#fff'
                            }}
                        >
                            {name + ' (' + count + ')'}
                        </Box>
                    </Box>
                    <IconButton
                        aria-label="delete"
                        disabled={name === 'default' || !!count}
                        color='error'
                        sx={{
                            visibility: (name === 'default') ? 'hidden' : 'unset',
                        }}
                        onClick={() => setConfirmationDialogOpen(name)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Box>
            })}
        </Box>
        <Dialog
            onClose={() => setConfirmationDialogOpen(null)}
            open={ConfirmationDialogOpen !== null}
        >
            <DialogTitle id="simple-dialog-title">Confirma la eliminación de la categoría</DialogTitle>
            <DialogContent>
                {props.single + ': ' + ConfirmationDialogOpen}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => setConfirmationDialogOpen(null)}
                    color="primary"
                >
                    Cancelar
                </Button>
                <Button
                    onClick={() => {
                        set(ref(rtdb, "public/Categories/" + props.database + '/' + ConfirmationDialogOpen), null)
                        setConfirmationDialogOpen(null)
                    }}
                    color="primary"
                >
                    Borrar
                </Button>
            </DialogActions>
        </Dialog>
    </Box>
}

export default Component