import { Box, Button, Paper } from "@mui/material";
import React, { useState } from "react";
import AppMenuEdit from './AppMenuEdit.js';
import CategoriesList from './CategoriesList.js';
import BadgesList from './BadgeList.js';
import PicsClean from './PicsClean.js';

function Component(props) {
    const [MenuSelection, setMenuSelection] = useState('Menu');
    return (
        <Paper
            elevation={1}
            sx={{
                p: 2,
                flex: {
                    xs: '100% 0 1',
                    md: '50vw 0 0',
                },
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                maxHeight: '95%',
                overflow: 'auto',
                m: 2,
                mb: 'auto',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                }}
            >
                <Button
                    variant={MenuSelection === 'Menu' ? "contained" : "outlined"}
                    onClick={() => setMenuSelection('Menu')}
                    sx={{
                        fontSize: 'large',
                        borderRadius: '3em',
                        p: 1,
                    }}
                >
                    <big className='fa-fw fas fa-ballot-check' />
                </Button>
                <Button
                    variant={MenuSelection === 'Places' ? "contained" : "outlined"}
                    onClick={() => setMenuSelection('Places')}
                    sx={{
                        fontSize: 'large',
                        borderRadius: '3em',
                        p: 1,
                    }}
                >
                    <big className='fa-fw fas fa-store-alt' />
                </Button>
                <Button
                    variant={MenuSelection === 'Products' ? "contained" : "outlined"}
                    onClick={() => setMenuSelection('Products')}
                    sx={{
                        fontSize: 'large',
                        borderRadius: '3em',
                        p: 1,
                    }}
                >
                    <big className='fa-fw fas fa-box-heart' />
                </Button>
                <Button
                    variant={MenuSelection === 'Badges' ? "contained" : "outlined"}
                    onClick={() => setMenuSelection('Badges')}
                    sx={{
                        fontSize: 'large',
                        borderRadius: '3em',
                        p: 1,
                    }}
                >
                    <big className='fa-fw fas fa-badge-percent' />
                </Button>
                <Button
                    variant={MenuSelection === 'Pics' ? "contained" : "outlined"}
                    onClick={() => setMenuSelection('Pics')}
                    sx={{
                        fontSize: 'large',
                        borderRadius: '3em',
                        p: 1,
                    }}
                >
                    <big className='fa-fw fas fa-images' />
                </Button>
            </Box>
            {MenuSelection === 'Places' ? (
                <CategoriesList
                    Title='Categorías de Locales'
                    Categories={props.PlaceCategories}
                    List={props.Places}
                    Pictures={props.Pictures}
                    database='Places'
                    single='Locales'
                    DarkMode={props.DarkMode}
                />
            ) : ''}
            {MenuSelection === 'Products' ? (
                <CategoriesList
                    Title='Categorías de Producto'
                    Categories={props.ProductCategories}
                    List={props.Products}
                    Pictures={props.Pictures}
                    database='Products'
                    single='Producto'
                    DarkMode={props.DarkMode}
                />
            ) : ''}
            {MenuSelection === 'Menu' ? (
                <AppMenuEdit
                    PlaceCategories={props.PlaceCategories}
                    ProductCategories={props.ProductCategories}
                    HomeMenu={props.HomeMenu}
                    DarkMode={props.DarkMode}
                />
            ) : ''}
            {MenuSelection === 'Badges' ? (
                <BadgesList
                    Badges={props.Badges}
                    List={props.Products}
                    Pictures={props.Pictures}
                    DarkMode={props.DarkMode}
                />
            ) : ''}
            {MenuSelection === 'Pics' ? (
                <PicsClean
                    Badges={props.Badges}
                    Products={props.Products}
                    Places={props.Places}
                    Pictures={props.Pictures}
                    DarkMode={props.DarkMode}
                />
            ) : ''}

        </Paper>
    )
}

export default Component