import React, { useState, useEffect, useRef } from "react";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { db, auth, rtdb } from './firebase.js'
import { onAuthStateChanged } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { ref, set, onValue, push, child } from "firebase/database";
import { Box, Button, Drawer, IconButton, Link, TextField } from "@mui/material";
import AddBoxIcon from '@mui/icons-material/AddBox';
import SearchIcon from '@mui/icons-material/Search';
import Nav from './Nav.js'
import SignIn from './SignIn.js'
import PlaceList from './PlaceList.js'
import PlaceEdit from './PlaceEdit.js'
import ProductEdit from './ProductEdit.js'
import CategoryEdit from './CategoryEdit.js'
import CancelIcon from "@mui/icons-material/Cancel";

function Component(props) {
    const [User, setUser] = useState({});
    const [Loaded, setLoaded] = useState(false);
    const [DarkMode, setDarkMode] = useState(localStorage.hasOwnProperty('darkmode') ? localStorage.getItem('darkmode') === 'true' : window.matchMedia('(prefers-color-scheme: dark)').matches);
    const [ActivePlaceId, setActivePlaceId] = useState('');
    const [ActiveProductId, setActiveProductId] = useState('');
    const [ActivePlace, setActivePlace] = useState({});
    const [ActiveProduct, setActiveProduct] = useState({});
    const [PlaceCategories, setPlaceCategories] = useState({});
    const [ProductCategories, setProductCategories] = useState({});
    const [Badges, setBadges] = useState({});
    const [HomeMenu, setHomeMenu] = useState({});
    const [Places, setPlaces] = useState([]);
    const [Products, setProducts] = useState([]);
    const [Pictures, setPictures] = useState({});
    const [Search, setSearch] = useState('');
    const [PlacesSwipeableOpen, setPlacesSwipeableOpen] = useState(window.innerWidth < 600);
    const [ProductsSwipeableOpen, setProductsSwipeableOpen] = useState(false);
    const [CategoriesEditShow, setCategoriesEditShow] = useState(false);
    const UserSnap = useRef();
    const dbListener = useRef(false);

    useEffect(() => {
        setLoaded(true);
        if (Loaded) {
            onAuthStateChanged(auth, (loggedUser) => {
                if (loggedUser) {
                    setUser({ uid: loggedUser.uid });
                    onValue(ref(rtdb, 'Users/' + loggedUser.uid), (snapshot) => {
                        if (snapshot.val())
                            setUser({ ...snapshot.val(), uid: loggedUser.uid, loaded: true });
                        else {
                            set(ref(rtdb, "Users/" + loggedUser.uid), {
                                name: loggedUser.displayName || '',
                                email: loggedUser.email || '',
                                pic: loggedUser.photoURL || '',
                                admin: false
                            });
                            setDoc(doc(db, "Users", loggedUser.uid), {
                                email: loggedUser.email || '',
                                admin: false
                            });
                        }
                    });
                    if (!dbListener.current) {
                        onValue(ref(rtdb, 'public'), (snapshot) => {
                            const data = snapshot.val();
                            setProducts(
                                Object.keys(data.Products || {}).map(
                                    prid => {
                                        return {
                                            prid: prid,
                                            ...data.Products[prid]
                                        }
                                    }
                                )
                            );
                            let productsByPlace = {};
                            for (let prid in data.Products) {
                                let plid = data.Products[prid].plid, pre = {};
                                pre[prid] = data.Products[prid];
                                productsByPlace[plid] = {
                                    ...(productsByPlace[plid] || {}),
                                    ...pre
                                }
                            }
                            setPlaces(
                                Object.keys(data.Places || {}).map(
                                    plid => {
                                        return {
                                            plid: plid,
                                            ...data.Places[plid],
                                            products: productsByPlace[plid] || {},
                                        }
                                    }
                                )
                            );
                            setPictures(data.Pictures || {})
                            setPlaceCategories(data.Categories.Places || {})
                            setProductCategories(data.Categories.Products || {})
                            setBadges(data.Categories.Badge || {})
                            setHomeMenu(data.Categories.HomeMenu || {})
                        });
                    };

                } else {
                    setUser({});
                    if (UserSnap.current) UserSnap.current();
                }
            });
        }
    }, [Loaded]);

    useEffect(() => {
        const getActivePlace = () => {
            if (!ActivePlaceId) return {};
            for (let place of Places)
                if (place.plid === ActivePlaceId)
                    return {
                        ...place,
                        ProductCount: Object.keys(place.products || {}).length,
                    }
            return { plid: ActivePlaceId, empty: true }
        }
        setActivePlace(getActivePlace())
    }, [ActivePlaceId, Places])

    useEffect(() => {
        const getActiveProduct = () => {
            if (!ActiveProductId) return {};
            for (let product of Products)
                if (product.prid === ActiveProductId) {
                    if (product.plid === ActivePlaceId)
                        return product;
                    else
                        setActiveProductId('');
                }
            return {
                prid: ActiveProductId,
                plid: ActivePlaceId
            }
        }
        setActiveProduct(getActiveProduct())
    }, [ActiveProductId, Products, ActivePlaceId])

    const seachBox = (
        <Box sx={{ display: 'flex', alignItems: 'flex-end', mb: 2 }}>
            <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <TextField
                label="Buscar…"
                variant="standard"
                onChange={e => setSearch(e.target.value)}
                value={Search}
            />
            {Search ? (
                <IconButton
                    sx={{ ml: -4, mb: -.5 }}
                    onClick={() => setSearch('')}
                >
                    <CancelIcon />
                </IconButton>
            ) : ''}
        </Box>
    )

    const placesDrawer = (
        <Box
            sx={{
                p: 2,
                py: {
                    md: 4,
                },
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexGrow: 1,
            }}
        >
            {seachBox}
            {User.admin ? (
                <Button
                    variant="outlined"
                    startIcon={<AddBoxIcon />}
                    onClick={() => {
                        setActivePlaceId(push(child(ref(rtdb), '/public/Places')).key);
                        setActiveProductId('');
                        setPlacesSwipeableOpen(false);
                    }}
                >
                    Nuevo Local
                </Button>
            ) : ''}
            <PlaceList
                listObj={Places}
                ActivePlaceId={ActivePlaceId || ''}
                setActiveId={id => {
                    setActivePlaceId(id);
                    setActiveProductId('');
                    setPlacesSwipeableOpen(false);
                }}
                Search={Search}
                categories={PlaceCategories}
            />
            <Box sx={{ flexGrow: 1, minHeight: 16 }} />
            <Link
                href="https://bollo.com.ar"
                underline="none"
                rel="noreferrer"
                target='_blank'
            >
                <Button
                    variant="text"
                    title="Developed by Bollo"
                    sx={{
                        minWidth: 'unset',
                        p: .5
                    }}
                >
                    <Box
                        component='div'
                        sx={{
                            backgroundImage: 'url(https://bollo.com.ar/logo.png)',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            p: 2
                        }}
                    >
                    </Box>
                </Button>
            </Link>
        </Box>
    )

    const productDrawer = (
        <Box
            sx={{
                p: 2,
                py: {
                    md: 4,
                },
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexGrow: 1,
            }}
        >
            {window.innerWidth < 600 ? seachBox : ''}
            {User.admin ? (
                <Button
                    variant="outlined"
                    startIcon={<AddBoxIcon />}
                    onClick={() => {
                        setActiveProductId(push(child(ref(rtdb), '/public/Products')).key);
                        setProductsSwipeableOpen(false);
                    }}
                >
                    Nuevo Producto
                </Button>
            ) : ''}
            <PlaceList
                listObj={Products}
                Products={true}
                ActivePlaceId={ActivePlaceId || ''}
                ActiveProductId={ActiveProductId || ''}
                setActiveId={id => {
                    setActiveProductId(id);
                    setProductsSwipeableOpen(false);
                }}
                categories={ProductCategories}
            />
        </Box>
    )

    return (
        <ThemeProvider theme={createTheme({ palette: { mode: DarkMode ? 'dark' : 'light', } })}        >
            <CssBaseline />
            <Nav
                User={User}
                DarkMode={DarkMode}
                setDarkMode={setDarkMode}
                SwipeableToggle={() => { setPlacesSwipeableOpen(!PlacesSwipeableOpen); setCategoriesEditShow(false); }}
                CreateProduct={() => setActiveProductId(push(child(ref(rtdb), '/public/Products')).key)}
                ProductsSwipeableToggle={() => setProductsSwipeableOpen(!ProductsSwipeableOpen)}
                showProductsIcon={!!ActivePlaceId && !PlacesSwipeableOpen && !ActivePlace.empty}
                ProductCount={ActivePlace.ProductCount}
                showCategoriesIcon={PlacesSwipeableOpen || !ActivePlaceId || window.innerWidth >= 900}
                CategoriesEditToggle={() => setCategoriesEditShow(!CategoriesEditShow)}
            />
            <Box sx={{
                display: 'flex',
                flexGrow: 1,
                overflow: 'auto',
            }}>
                {(User.uid && !CategoriesEditShow) ? (
                    <Box
                        sx={{
                            display: {
                                xs: 'none',
                                md: 'flex',
                            },
                            height: 1,
                        }}
                    >
                        {placesDrawer}
                    </Box>
                ) : ''}
                <Box sx={{ flexGrow: 1 }} />
                {!User.uid ? (
                    <SignIn />
                ) : ''}
                {(User.uid && ActivePlaceId && !ActiveProductId && !CategoriesEditShow) ?
                    (
                        <PlaceEdit
                            Pictures={Pictures}
                            ActivePlace={ActivePlace}
                            DarkMode={DarkMode}
                            Admin={User.admin || false}
                            categories={PlaceCategories}
                            ClosePlace={() => setActivePlaceId('')}
                        />
                    ) : ''}
                {(User.uid && ActiveProductId && !CategoriesEditShow) ?
                    (
                        <ProductEdit
                            Pictures={Pictures}
                            ActivePlace={ActivePlace}
                            ActiveProduct={ActiveProduct}
                            DarkMode={DarkMode}
                            Admin={User.admin || false}
                            categories={ProductCategories}
                            Badges={Badges}
                            CloseProduct={() => setActiveProductId('')}
                        />
                    ) : ''}
                {(CategoriesEditShow && User.admin) ? (
                    <CategoryEdit
                        PlaceCategories={PlaceCategories}
                        ProductCategories={ProductCategories}
                        Badges={Badges}
                        Places={Places}
                        Products={Products}
                        HomeMenu={HomeMenu}
                        Pictures={Pictures}
                        DarkMode={DarkMode}
                    />
                ) : ''}
                <Box sx={{ flexGrow: 1 }} />
                {(User.uid && ActivePlaceId && !ActivePlace.empty && !CategoriesEditShow) ? (
                    <Box
                        sx={{
                            display: {
                                xs: 'none',
                                md: 'flex',
                            },
                            pt: 8,
                            height: 1,
                        }}
                    >
                        {productDrawer}
                    </Box>
                ) : ''}
            </Box>
            <Drawer
                sx={{
                    display: {
                        xs: 'block',
                        md: 'none',
                    }
                }}
                anchor='bottom'
                open={User.uid && ((PlacesSwipeableOpen || !ActivePlaceId) && !CategoriesEditShow)}
                onClose={() => setPlacesSwipeableOpen(false)}
            >
                {placesDrawer}
            </Drawer>
            <Drawer
                sx={{
                    display: {
                        xs: 'block',
                        md: 'none',
                    }

                }}
                anchor='bottom'
                open={!!ActivePlaceId && ProductsSwipeableOpen && !PlacesSwipeableOpen && !ActivePlace.empty}
                onClose={() => setProductsSwipeableOpen(false)}
            >
                {productDrawer}
            </Drawer>
        </ThemeProvider>
    )
}

export default Component