import React from "react";
import { Box, Button } from "@mui/material";
import TextStyles from './TextStyles.js'
import { ref, set } from "firebase/database";
import { ref as storageRef, listAll, getDownloadURL, deleteObject } from "firebase/storage";
import { rtdb, storage } from "./firebase.js";

function Component(props) {
    const removeUnused = () => {
        let usedArray = [], n = 0;
        for (let pic in props.Badges)
            usedArray.push(props.Badges[pic]);
        for (let place of props.Places) {
            usedArray.push(place.promotedImg);
            for (let pic of place.pics)
                usedArray.push(pic);
        }
        for (let prod of props.Products)
            for (let pic of prod.pics)
                usedArray.push(pic);
        usedArray = [...new Set(usedArray)];
        for (let hash in props.Pictures)
            if (!usedArray.includes(hash)) {
                n++;
                deleteObject(storageRef(storage, `pics/${hash}.jpg`))
                    .then(() => { }).catch((error) => { });
                deleteObject(storageRef(storage, `pics/${hash}.png`))
                    .then(() => { }).catch((error) => { });
            }
        rebuildPicsDb();
        alert(`Se quitaron ${n} imágenes no usadas`);
    };
    const removeOrphans = () => {
        let pr = 0, pl = 0;
        props.Products.forEach(prod => {
            let pics = prod.pics.filter(hash => !!props.Pictures[hash])
            if (pics.length !== prod.pics.length) {
                pr++
                set(ref(rtdb, `/public/Products/${prod.prid}/pics`), pics)
            }
        })
        props.Places.forEach(place => {
            let pics = place.pics.filter(hash => !!props.Pictures[hash])
            if (pics.length !== place.pics.length) {
                pl++
                set(ref(rtdb, `/public/Places/${place.plid}/pics`), pics)
            }
            if (!!place.promotedImg && !props.Pictures[place.promotedImg])
                set(ref(rtdb, `/public/Places/${place.plid}/promotedImg`), '')
        })
        alert(`Se corrigieron ${pl} lugares y ${pr} productos`);
    }

    const rebuildPicsDb = () => {
        listAll(storageRef(storage, 'pics')).then((res) => {
            let active = Object.keys(props.Pictures);
            res.items.forEach((itemRef) => {
                let hash = itemRef.name.split('.')[0];
                if (!props.Pictures[hash]) {
                    getDownloadURL(itemRef).then(url => set(ref(rtdb, "public/Pictures/" + hash),
                        { ts: new Date().getTime(), token: url.split('token=')[1] }
                    ));
                } else
                    active = active.filter(item => item !== hash)
            });
            active.forEach((hash) => set(ref(rtdb, "public/Pictures/" + hash), null));
            alert(`Listo`);
        });
    }

    return <Box
        sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
        }}
    >
        <Box
            sx={{
                ...TextStyles.h2,
                textAlign: 'center',
            }}
        >
            Limpiar Imágenes
        </Box>
        <Button
            variant="outlined"
            onClick={() => removeUnused()}
            sx={{
                borderRadius: '3em',
                textTransform: 'none'
            }}
        >
            Quitar No Usados
        </Button>
        <Button
            variant="outlined"
            onClick={() => removeOrphans()}
            sx={{
                borderRadius: '3em',
                textTransform: 'none'
            }}
        >
            Quitar Huérfanos
        </Button>
        <Button
            variant="outlined"
            onClick={() => rebuildPicsDb()}
            sx={{
                borderRadius: '3em',
                textTransform: 'none'
            }}
        >
            Reconstruir DB
        </Button>
    </Box>
}

export default Component