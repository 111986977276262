import React, { useEffect, useState } from "react";
import { Box, Checkbox, FormControlLabel, FormGroup, IconButton, Card } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';

function Component(props) {
    const [TimePickerTemp, setTimePickerTemp] = useState({ days: [] });
    const [AddWorkingHoursDialogOpen, setAddWorkingHoursDialogOpen] = useState(false);
    const [SaveButonEnabled, setSaveButonEnabled] = useState(false);


    const weekDays = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"];

    const setTimePickerTempDay = (day, val) => {
        let temp = { ...TimePickerTemp };
        temp.days[day] = val;
        setTimePickerTemp(temp);
    }

    const setTimePickerTempTime = (time, to = false) => {
        if (isNaN(time)) return;
        let temp = { ...TimePickerTemp };
        if (to)
            temp.to = time;
        else
            temp.from = time;
        setTimePickerTemp(temp);
    }

    const WorkingHoursDialogCancel = () => {
        setAddWorkingHoursDialogOpen(false);
        setTimePickerTemp({ days: [] });
    }

    const WorkingHoursDialogSave = () => {
        setAddWorkingHoursDialogOpen(false);
        if (TimePickerTemp.from === undefined || TimePickerTemp.to === undefined) return;
        let temp = [TimePickerTemp.from, TimePickerTemp.to].sort(function (a, b) { return a - b }),
            tempArray = [...props.PlaceWorkingHours];
        for (let i = 0; i < 7; i++) {
            if (!!TimePickerTemp.days[i]) tempArray[i] = [...(tempArray[i] || []), temp].sort(function (a, b) { return a[0] - b[0] })
            else if (!tempArray[i]) tempArray[i] = [];
            let from = 0,
                dayMod = [],
                timeArray = [];
            tempArray[i].forEach(timeSet => {
                for (let t = Math.min(...timeSet); t <= Math.max(...timeSet); t++)
                    timeArray[t] = true;
            });
            for (let t = 0; t <= 1440; t++) {
                if (!timeArray[t] && !!timeArray[t + 1]) from = (t + 1);
                if (!!timeArray[t] && !timeArray[t + 1]) dayMod.push([from, t]);
            }
            tempArray[i] = dayMod;
        }
        props.setPlaceWorkingHours(tempArray);
        setTimePickerTemp({ days: [] });
    }

    const WorkingHoursRemove = (day, index) => {
        if (day === undefined || index === undefined) return;
        let tempArray = [...props.PlaceWorkingHours],
            removedObj = { days: [] },
            removed = tempArray[day].splice(index, 1);
        removedObj.from = removed[0][0];
        removedObj.to = removed[0][1];
        removedObj.days[day] = true;
        setTimePickerTemp(removedObj);
        props.setPlaceWorkingHours(tempArray);
    }

    const renderWorkingHoursItem = (WorkingHoursItem) => {
        const int2Str = int => {
            return ('00' + Math.floor(int)).slice(-2);
        }
        const intToHours = int => {
            if (int === 1439) int++;
            return int2Str(int / 60) + ':' + int2Str(int % 60);
        }
        return intToHours(Math.min(...WorkingHoursItem)) + '-' + intToHours(Math.max(...WorkingHoursItem));
    }

    useEffect(() => {
        let n = 0;
        TimePickerTemp.days.forEach(d => { if (d) n++ });
        setSaveButonEnabled(!!n && TimePickerTemp.from !== undefined && !TimePickerTemp.to !== undefined && TimePickerTemp.from !== !TimePickerTemp.to)
    }, [TimePickerTemp])

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 1,
            }}
        >
            <Box
                component="fieldset"
                sx={{
                    border: '1px solid ' + (props.DarkMode ? '#ffffff3b' : '#0000003b'),
                    borderRadius: 1,
                    display: 'flex',
                    justifyContent: 'start',
                    position: 'relative',
                    flexGrow: 1,
                }}
            >
                <legend
                    style={{
                        fontSize: '.75em',
                        opacity: .7,
                    }}
                >Horario</legend>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: 'wrap',
                        gap: 1,
                        p: 1,
                    }}
                >
                    {(!props.PlaceWorkingHours || !props.PlaceWorkingHours.length) ? '24/7' : ''}
                    {weekDays.map((day, i) => {
                        return (!!props.PlaceWorkingHours && !!props.PlaceWorkingHours[i] && props.PlaceWorkingHours[i].length) ?
                            (<Box
                                key={'RenderedWorkingHours' + day}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 1,
                                    alignItems: 'center',
                                }}
                            >
                                {day}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        gap: 1,
                                        // p: 1,
                                    }}
                                >
                                    {props.PlaceWorkingHours[i].map((WorkingHoursItem, j) => {
                                        return (
                                            <Box
                                                key={'RenderedWorkingHours' + day + WorkingHoursItem.join()}
                                                sx={[
                                                    {
                                                        border: 'solid 1px currentColor',
                                                        borderRadius: 2,
                                                        pl: 1,
                                                        py: .5,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        transition: 'border-color .3s',
                                                    },
                                                    {
                                                        '&:hover': {
                                                            borderColor: 'red',
                                                        },
                                                    },
                                                    {
                                                        '&:not(:hover) > button': {
                                                            fontSize: 0,
                                                        },
                                                    },
                                                ]}
                                            >
                                                {renderWorkingHoursItem(WorkingHoursItem)}
                                                <IconButton
                                                    aria-label="delete"
                                                    color="error"
                                                    size="small"
                                                    sx={{
                                                        transition: 'font-size .15s',
                                                        py: '3px'
                                                    }}
                                                    onClick={e => WorkingHoursRemove(i, j)}
                                                >
                                                    <CancelIcon
                                                        fontSize="inherit"
                                                    />
                                                </IconButton>
                                            </Box>
                                        )
                                    })}
                                </Box>
                            </Box>) : ''
                    })}
                </Box>
            </Box>
            {props.Admin ? (
                <IconButton
                    aria-label="add"
                    size="large"
                    onClick={e => setAddWorkingHoursDialogOpen(true)}
                >
                    <AddCircleOutlineIcon fontSize="inherit" />
                </IconButton>
            ) : ''}
            {(props.Admin && AddWorkingHoursDialogOpen) ? (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 2,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            zIndex: 1,
                            background: '#0008'
                        }}
                        onClick={e => setAddWorkingHoursDialogOpen(false)}
                    />
                    <Card
                        sx={{
                            py: 2,
                            px: 4,
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 2,
                            position: 'relative',
                            zIndex: 2,
                        }}
                    >
                        <FormGroup>
                            {weekDays.map((day, i) => {
                                return <FormControlLabel
                                    key={'TimePickerDay' + day}
                                    control={
                                        <Checkbox
                                            checked={!!TimePickerTemp.days[i]}
                                            onChange={e => setTimePickerTempDay(i, e.target.checked)}
                                        />
                                    }
                                    label={day}
                                />
                            })}
                        </FormGroup>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                pt: 1,
                            }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <FormGroup
                                    sx={{
                                        gap: 2,
                                    }}
                                >
                                    <TimePicker
                                        label="Desde"
                                        ampm={false}
                                        value={(TimePickerTemp.from !== undefined) ? dayjs(new Date(TimePickerTemp.from * 60000).toISOString().substring(0, 16)) : undefined}
                                        onChange={val => setTimePickerTempTime(isNaN(val.$H) ? NaN : (val.$H * 60 + (val.$m || 0)))}
                                    />
                                    <TimePicker
                                        label="Hasta"
                                        ampm={false}
                                        value={(TimePickerTemp.to !== undefined) ? dayjs(new Date(TimePickerTemp.to * 60000).toISOString().substring(0, 16)) : undefined}
                                        onChange={val => setTimePickerTempTime(isNaN(val.$H) ? NaN : (val.$H * 60 + (val.$m || 0)), true)}
                                    />
                                </FormGroup>
                            </LocalizationProvider>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'end',
                                    gap: 2,
                                }}
                            >
                                <IconButton
                                    aria-label="cancel"
                                    size="large"
                                    sx={{ opacity: .5, }}
                                    onClick={WorkingHoursDialogCancel}
                                >
                                    <CancelIcon
                                        fontSize="inherit"
                                    />
                                </IconButton>
                                <IconButton
                                    aria-label="save"
                                    color="success"
                                    size="large"
                                    disabled={!SaveButonEnabled}
                                    onClick={WorkingHoursDialogSave}
                                >
                                    <CheckCircleIcon
                                        fontSize="inherit"
                                    />
                                </IconButton>
                            </Box>
                        </Box>
                    </Card>
                </Box>
            ) : ''}
        </Box>
    )
}

export default Component
